define("discourse/plugins/discourse-data-explorer/discourse/routes/group-reports-index", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GroupReportsIndexRoute extends _discourse.default {
    model() {
      const group = this.modelFor("group");
      return (0, _ajax.ajax)(`/g/${group.name}/reports`).then(queries => {
        return {
          model: queries,
          group
        };
      }).catch(() => this.transitionTo("group.members", group));
    }
    afterModel(model) {
      if (!model.group.get("is_group_user") && !(this.currentUser && this.currentUser.admin)) {
        this.transitionTo("group.members", model.group);
      }
    }
    setupController(controller, model) {
      controller.setProperties(model);
    }
  }
  _exports.default = GroupReportsIndexRoute;
});