define("discourse/plugins/discourse-data-explorer/discourse/components/result-types/reltime", ["exports", "@ember/component", "@glimmer/component", "discourse/lib/formatter", "@ember/template", "@ember/template-factory"], function (_exports, _component, _component2, _formatter, _template, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{this.boundDateReplacement}}
  */
  {
    "id": "Qf3v0/KO",
    "block": "[[[1,[30,0,[\"boundDateReplacement\"]]]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/result-types/reltime.hbs",
    "isStrictMode": false
  });
  class Reltime extends _component2.default {
    get boundDateReplacement() {
      return (0, _template.htmlSafe)((0, _formatter.autoUpdatingRelativeAge)(new Date(this.args.ctx.value), {
        title: true
      }));
    }
  }
  _exports.default = Reltime;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Reltime);
});