define("discourse/plugins/discourse-data-explorer/discourse/templates/modal/query-help", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModalBody @title="explorer.help.modal_title">
    {{html-safe (i18n "explorer.help.auto_resolution")}}
    {{html-safe (i18n "explorer.help.custom_params")}}
    {{html-safe (i18n "explorer.help.default_values")}}
    {{html-safe (i18n "explorer.help.data_types")}}
  </DModalBody>
  */
  {
    "id": "TkMeO+56",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"explorer.help.modal_title\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[[28,[37,2],[\"explorer.help.auto_resolution\"],null]],null]],[1,\"\\n  \"],[1,[28,[35,1],[[28,[37,2],[\"explorer.help.custom_params\"],null]],null]],[1,\"\\n  \"],[1,[28,[35,1],[[28,[37,2],[\"explorer.help.default_values\"],null]],null]],[1,\"\\n  \"],[1,[28,[35,1],[[28,[37,2],[\"explorer.help.data_types\"],null]],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"d-modal-body\",\"html-safe\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/templates/modal/query-help.hbs",
    "isStrictMode": false
  });
});